var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('fieldset',[_c('Field',{attrs:{"error":_vm.formErrors.lastname || _vm.formErrors.firstname || _vm.formErrors.patronymic,"title":"ФИО","subtitle":"(отчество можно не указывать если нет)"},model:{value:(_vm.fullname),callback:function ($$v) {_vm.fullname=$$v},expression:"fullname"}})],1),_c('fieldset',[_c('fieldset',[_c('Date',{attrs:{"error":_vm.formErrors.birthday,"title":"Дата рождения"},model:{value:(_vm.form.birthday),callback:function ($$v) {_vm.$set(_vm.form, "birthday", $$v)},expression:"form.birthday"}}),_c('ZSwitch',{attrs:{"error":_vm.formErrors.gender,"title":"Пол","list":[{
                    key: '0',
                    value: 'Мужской'
                }, {
                    key: '1',
                    value: 'Женский'
                }]},model:{value:(_vm.form.gender),callback:function ($$v) {_vm.$set(_vm.form, "gender", $$v)},expression:"form.gender"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }