import '@/helpers/metrics/yandex.js'
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'

// const appHeight = () => {
//     const doc = document.documentElement
//     doc.style.setProperty('--app-height', `100svh`)
// }
// window.addEventListener('resize', appHeight)
// // appHeight()

store.dispatch('application/init')

Vue.config.productionTip = false

import CommonTemplate from '@/templates/common/Common'
import StepTemplate from '@/templates/step/Step'

Vue.component('CommonTemplate', CommonTemplate)
Vue.component('StepTemplate', StepTemplate)


import Field from '@/components/form/field/Field'
import Button from '@/components/gui/button/Button'

Vue.component('Button', Button)
Vue.component('Field', Field)

Vue.mixin({
    computed: {
        $DICTIONARY() {
            return this.$store.state.dictionary.dictionary
        }
    }
})

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app')
