var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Page',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Редактировать данные ")]},proxy:true}])},[_c('FormTemplate',{staticClass:"edit-page",attrs:{"action":"Обновить"},on:{"submit":_vm.submit}},[_c('fieldset',[_c('Field',{attrs:{"error":_vm.formErrors.lastname,"title":"Фамилия"},model:{value:(_vm.form.lastname),callback:function ($$v) {_vm.$set(_vm.form, "lastname", $$v)},expression:"form.lastname"}}),_c('Field',{attrs:{"error":_vm.formErrors.firstname,"title":"Имя"},model:{value:(_vm.form.firstname),callback:function ($$v) {_vm.$set(_vm.form, "firstname", $$v)},expression:"form.firstname"}})],1),_c('fieldset',[_c('Field',{attrs:{"error":_vm.formErrors.patronymic,"title":"Отчество","subtitle":"(если есть)"},model:{value:(_vm.form.patronymic),callback:function ($$v) {_vm.$set(_vm.form, "patronymic", $$v)},expression:"form.patronymic"}}),_c('Field',{attrs:{"error":_vm.formErrors.email,"title":"E-mail"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1),_c('fieldset',[_c('Date',{attrs:{"error":_vm.formErrors.birthday,"title":"Дата рождения"},model:{value:(_vm.form.birthday),callback:function ($$v) {_vm.$set(_vm.form, "birthday", $$v)},expression:"form.birthday"}}),_c('ZSwitch',{attrs:{"error":_vm.formErrors.gender,"title":"Пол","list":[{
                    key: '0',
                    value: 'Мужской'
                }, {
                    key: '1',
                    value: 'Женский'
                }]},model:{value:(_vm.form.gender),callback:function ($$v) {_vm.$set(_vm.form, "gender", $$v)},expression:"form.gender"}})],1),_c('fieldset',[_c('Address',{attrs:{"error":_vm.formErrors.addrcity,"title":"Адрес регистрации"},model:{value:(_vm.form.addrcity),callback:function ($$v) {_vm.$set(_vm.form, "addrcity", $$v)},expression:"form.addrcity"}})],1),_c('fieldset',[_c('fieldset',[_c('Field',{attrs:{"error":_vm.formErrors.passportseries,"title":"Серия паспорта","mask":"## ##","valueWithoutMask":""},model:{value:(_vm.form.passportseries),callback:function ($$v) {_vm.$set(_vm.form, "passportseries", $$v)},expression:"form.passportseries"}}),_c('Field',{attrs:{"error":_vm.formErrors.passportnumber,"title":"Номер","mask":"### ###","valueWithoutMask":""},model:{value:(_vm.form.passportnumber),callback:function ($$v) {_vm.$set(_vm.form, "passportnumber", $$v)},expression:"form.passportnumber"}})],1),_c('fieldset',[_c('Field',{attrs:{"error":_vm.formErrors.passportissuecode,"title":"Код подразделения","mask":"###-###","valueWithoutMask":""},model:{value:(_vm.form.passportissuecode),callback:function ($$v) {_vm.$set(_vm.form, "passportissuecode", $$v)},expression:"form.passportissuecode"}}),_c('Date',{attrs:{"title":"Дата выдачи","error":_vm.formErrors.passportissuedate},model:{value:(_vm.form.passportissuedate),callback:function ($$v) {_vm.$set(_vm.form, "passportissuedate", $$v)},expression:"form.passportissuedate"}})],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }